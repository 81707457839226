export const LANDING = '/';
export const LOGIN = '/login';
export const PROFILE = '/profile';
export const VERIFY_PROFILE = '/profile/verify';

export const DATION_INSTANCES = '/dation-instances';
export const PORTAL_STATISTICS = '/statistieken';

export const COMPANIES = '/companies';
export const IMPORT_COMPANY = '/companies/import';
export const USERS = '/users';
export const DASHBOARD = '/dashboard';
export const EMPLOYEES = '/medewerkers';
export const SETTINGS = '/instellingen';
export const ESTABLISHMENTS = '/vestiging';

export const COURSE_PLANNING = '/planning';

export const CERTIFICATES = '/certificates';

export const INVENTORY = '/cursusagenda';

export const LOGBOOK = '/logboek';

export const EDIT_USER = '/users/edit';

export const ADMIN = '/admin';

// Route and http response code used for the maintenance mode
export const MAINTENANCE = '/maintenance';
export const MAINTENANCE_ERROR_CODE = 503;

export const resetPasswordRequestPage = () => '/reset-password';
export const resetPasswordPage = () => '/reset';

export const companyPage = id => `${COMPANIES}/${id}`;
export const employeeDetailPage = id => `${EMPLOYEES}/${id}`;
export const logbookEntryDetailPage = id => `${LOGBOOK}/${id}`;

export const getExistingCompaniesByDationInstance = dationInstanceId => `companies?dationIdentifiers.dationInstance[]=${dationInstanceId}`;
export const getCompaniesFromDationInstance = dationInstanceId => `dation_instances/${dationInstanceId}/companies`;
export const getEmployeeDetailsWithEnrollments = employeeId => `students/${employeeId}?expand[]=enrollments`;
export const getEmployeeAppointmentsPerEducation = employeeId => `students/${employeeId}/appointments-per-education`;
export const getCompanyContactPersons = companyId => `${COMPANIES}/${companyId}/contact_persons`;

export const enrollCompanyStudents = trainingId => `/trainings/${trainingId}/company-enrollments`;
export const getTrainingsForCompany = companyId => `/trainings?companyId=${companyId}`;
export const postTraining = () => '/trainings';
export const updateTraining = id => `${postTraining()}/${id}`;
export const getPlannedTrainingsForCompany = companyId => `/companies/${companyId}/trainings`;
export const exportCompanyEmployees = companyId => `/companies/${companyId}/export-students`;

export const postExternalTrainingEnrollment = () => '/external_training_enrollments';

export const enrollments = () => '/enrollments';
export const updateEnrollment = id => `${enrollments()}/${id}`;
export const getSendInviteUrl = id => `/company_contact_persons/${id}/send_invitation`;
export const inviteContactPersonToPortal = id => `/company_contact_persons/${id}/invite_to_portal`;
export const createCompanyContactPersonUser = companyId => `companies/${companyId}/add_user`;
export const updateCompanyContactPerson = id => `/company_contact_people/${id}`;
export const deleteCompanyContactPerson = (contactPersonId, companyId) => `/companies/${companyId}/contact_persons/${contactPersonId}`;
export const updateCompanyContactPersonUser = id => `/company_contact_person_users/${id}`;
export const getCompanyStudents = id => `companies/${id}/students`;
export const importCompanyStudents = id => `companies/${id}/import-students`;

export const addEmployee = '/students';
export const editEmployee = id => `/students/${id}`;
export const sendStudentsApplicationEndpoint = () => '/students/email-application';
export const importStudentsTemplate = () => '/students/import-students-template';

export const getCode95CyclesForStudent = studentId => `students/${studentId}/code95_cycles`;
export const postCode95Cycle = () => '/code95_cycles';
export const updateCode95Cycle = id => `${postCode95Cycle()}/${id}`;

export const dationInstanceEndpoint = () => '/dation_instances';
export const getDationInstance = id => `/dation_instances/${id}`;
export const updateDationInstance = id => `/dation_instances/${id}`;
export const dationInstancePage = id => `${DATION_INSTANCES}/${id}`;

export const dationInstanceUserEndpoint = () => '/dation_instance_users';
export const getEnrollmentStatistics = () => '/statistics/enrollments';

export const inviteDationInstanceUserToPortal = id => `${dationInstanceUserEndpoint()}/${id}/invite_to_portal`;
export const dationInstanceUserList = dationInstanceId => `${getDationInstance(dationInstanceId)}/users`;
export const updateDationInstanceUser = id => `${dationInstanceUserEndpoint()}/${id}`;

export const getLogbookData = () => '/logbook_entries';
export const getLogbookDetails = id => `${getLogbookData()}/${id}`;
export const getLogbookActions = () => '/logbook_entry_actions';

export const postEstablishment = () => '/establishments';
export const updateEstablishment = id => `/establishments/${id}`;
export const getEstablishmentsForCompany = companyId => `${COMPANIES}/${companyId}/establishments`;

export const trainingReservations = () => '/training_reservations';
export const getTrainingReservation = id => `${trainingReservations()}/${id}`;

export const postDocument = () => '/documents';
export const putDocument = id => `/documents/${id}`;
export const downloadDocument = documentId => `documents/${documentId}/download`;
export const getDocumentsForStudent = employeeId => `students/${employeeId}/documents`;

export const getChecklistForCompanyStudents = companyId => `company/${companyId}/student_checklist_values`;
export const getCertificatesForCompany = companyId => `companies/${companyId}/certificates`;

export const postFreshchatRestoreId = () => '/freshchat/restore_id';
export const loginAttempt = () => 'oauth/v2/auth';
