import React, { useEffect, useState } from 'react';
import { FormLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CcvCodes from '../../utils/code95.json';

function CcvCodeSelect({ value, handleChange }) {
	const { t } = useTranslation();
	const [selectedOption, setSelectedOption] = useState(null);

	useEffect(() => {
		if(value) {
			const ccvCode = CcvCodes.find(ccv => ccv.ccvCode === value);
			ccvCode && setSelectedOption({ label: `${ccvCode.ccvCode} - ${ccvCode.description}`, value: ccvCode.ccvCode });
		}
	}, [value]);

	const onChange = option => {
		handleChange(CcvCodes.find(ccv => ccv.ccvCode === option.value) || null);
	};

	return (
		<>
			<FormLabel>{t('code95.code')}</FormLabel>
			<Select
				value={selectedOption}
				placeholder={t('code95.select_code')}
				options={
					CcvCodes.map(({ ccvCode, description }) => ({ label: `${ccvCode} - ${description}`, value: ccvCode }))
				}
				onChange={onChange}
				isSearchable
			/>
		</>
	);
}

CcvCodeSelect.propTypes = {
	value: PropTypes.string,
	handleChange: PropTypes.func.isRequired,
};
export default CcvCodeSelect;
